.section-destination{
  padding: 0px 20px;
}
.section-destination .slick-prev,.section-destination
.slick-next {
  background-color: #ffffffc3 !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 50% !important;
  border: 1px solid #0081C3;
  box-shadow: 1px 1px 4px 0px #00000040;
}
.section-destination .slick-next {
  right: -15px !important;
}
.section-destination .slick-next::before,.section-destination
.slick-prev::before {
  font-size: 30px !important;
  position: relative;
  top: 6px;
}

.section-destination .slick-prev {
  left: -15px !important;
  z-index: 9 !important;
}
.section-destination .slick-next::before {
  background: url(../../../assets/right.png);
  background-repeat: no-repeat;
  color: transparent;
}
.section-destination .slick-prev::before {
  background: url(../../../assets/left.png);
  background-repeat: no-repeat;
  color: transparent;
}
.section-destination .slick-track {
  display: flex;
  gap: 20px;
}

@media screen and (max-width:768px) {
.section-destination .slick-track {
  gap: 10px;
}
}
@media screen and (max-width:767px) {
  .section-destination{
    padding: 0px 10px;
  }
}