/* .hotelcard .card {
    background: url("../../assets/multi-slider1.svg") no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 375px;
    border-radius: 12px;
    border: 0px;
    margin: 10px;
  }
  .hotelcard .card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
  }
  .hotelcard .card .card-body .card-title{
   
  }
  .hotelcard .card .card-body p{
    margin-bottom: 0px;
    font-weight: 500;
  } */


.hotelcard {
}

.hotelcard .card {
  position: relative;
  color: #fff;
  border-radius: 12px;
  border: 0px;
  height: 300px;
  width: 97%;
}

.hotelcard .card img {
  border-radius: 12px;
  border: 0px;
  object-fit: cover;
  min-height: 100%;
  width: 100%;
  filter: brightness(0.5);
}

.hotelcard .card .card-body {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.hotelcard .card .card-body .card-title {
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
}

.hotelcard .card .card-body p {
  margin-bottom: 0px;
  font-weight: 500;
  font-size: 17px;
  text-align: justify;
}

@media screen and (max-width:768px) {

  .hotelcard .card .card-body p {
    font-size: 13px;
  }

  .hotelcard {
    width: 40vw;
  }

  .hotelcard .card {
    height: 240px;
    width: 100%;
  }

  .hotelcard .card .card-body .card-title {
    font-size: 16px;
  }
}